import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import Logo from "../Assets/Daewoo_logo.png";
import { ReactComponent as Freezer } from "../Assets/freezer.svg";
import { ReactComponent as Heladera } from "../Assets/heladera.svg";
import { ReactComponent as Horno } from "../Assets/horno.svg";
import { ReactComponent as Microondas } from "../Assets/microondas.svg";
import { ReactComponent as Lavarropas } from "../Assets/lavarropas.svg";
import { ReactComponent as Lupita } from "../Assets/lupita.svg";
import { ReactComponent as ArrowDown } from "../Assets/arrow-down.svg";
import { ReactComponent as Burger } from "../Assets/burger.svg";
import Hamburger from "hamburger-react";
import Search from "./Product/Search";

// import {
//   Button,
//   Container,
//   Form,
//   Nav,
//   Navbar,
//   NavDropdown,
// } from "react-bootstrap";

function Header() {
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const divProducts = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (divProducts.current && !divProducts.current.contains(event.target)) {
        handleButtonClickOut();
        handleHambClick();
      }
    }
    function handleEscape(event) {
      if (event.key === "Escape") {
        handleButtonClickOut();
        handleHambClick();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscape);
    };
  }, [divProducts]);

  const handleHambClick = () => {
    setOpen(false);
    document.body.style.overflow = "auto";
  };

  const handleButtonClickOut = () => {
    setIsAlertVisible(false);
  };

  const handleButtonClick = () => {
    setIsAlertVisible(true);
  };

  // const handleButtonHover = () => {
  //   setBgBorder(true);
  // };

  // const handleButtonOut = () => {
  //   setIsAlertVisible(false);
  //   setBgBorder(false);
  // };

  // const handleButtonHoverOut = () => {
  //   setBgBorder(false);
  // };

  const [isVisible, setIsVisible] = useState(false);

  let changeBorder = {
    border: "transparent",
    color: "black",
    height: "75px",
    display: "flex",
  };

  if (isVisible === true) {
    changeBorder = {
      borderBottom: "solid #3c5ea9 2px",
      color: "#3c5ea9",
      height: "75px",
      display: "flex",
    };
  }

  const toggleRef = useRef(null);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (toggleRef.current && !toggleRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [toggleRef]);

  return (
    <>
      <nav>
        <div className="nav-cont">
          <div className="left-nav-cont">
            <Link to="/">
              <img
                src={Logo}
                width={150}
                alt="logo"
                style={{ marginRight: "25px" }}
                onClick={handleHambClick}
              />
            </Link>
            <div style={changeBorder} onClick={handleClick} ref={toggleRef}>
              <p className="header-title">
                Productos
                <ArrowDown
                  width={15}
                  style={{ marginTop: "7px", marginLeft: "10px" }}
                />
              </p>
            </div>
          </div>
          <div className="right-nav-cont">
            <Search />
            <Link
              to="https://www.paris.cl/search?q=Daewoo&prefn1=isMarketplace&prefv1=Paris"
              target="_blank"
            >
              <div className="cta-nav"> Ir a tienda</div>
            </Link>
          </div>
          <Hamburger
            id="burger"
            toggled={isOpen}
            toggle={setOpen}
            color="#3c5ea9"
            onToggle={(toggled) => {
              if (toggled) {
                document.body.style.overflow = "hidden";
                console.log("hidden");
              } else {
                document.body.style.overflow = "auto";
              }
            }}
          />
        </div>
      </nav>
      {isVisible && (
        <div className="alert-container" ref={divProducts}>
          <div className="alert-inner">
            <div>
              <h4>NUESTROS PRODUCTOS </h4>
            </div>

            <div
              className="header-products-cont"
              onClick={handleButtonClickOut}
              style={{ marginTop: "50px" }}
            >
              <Link to="/heladeras">
                <div className="products-header">
                  <Heladera width={20} />
                  <p className="title-products-header">Heladeras</p>
                  <p className="sub-products-header">
                    Descubre la heladera perfecta para tu hogar.
                  </p>
                </div>
              </Link>
              <Link to="/microondas">
                <div className="products-header">
                  <Microondas width={20} />
                  <p className="title-products-header">Microondas</p>
                  <p className="sub-products-header">
                    Descubre la heladera perfecta para tu hogar.
                  </p>
                </div>
              </Link>
              <Link to="/lavadoras">
                <div className="products-header">
                  <Lavarropas width={20} />
                  <p className="title-products-header">Lavarropas/Secarropas</p>
                  <p className="sub-products-header">
                    Descubre la heladera perfecta para tu hogar.
                  </p>
                </div>
              </Link>
              <Link to="/freezers">
                <div className="products-header">
                  <Freezer width={20} />
                  <p className="title-products-header">Freezers</p>
                  <p className="sub-products-header">
                    Descubre la heladera perfecta para tu hogar.
                  </p>
                </div>
              </Link>
              <Link to="/hornos">
                <div className="products-header">
                  <Horno width={20} />
                  <p className="title-products-header">Hornos eléctricos</p>
                  <p className="sub-products-header">
                    Descubre la heladera perfecta para tu hogar.
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}

      {isOpen && (
        <div className="hamb-cont" onClick={handleHambClick}>
          {/* <form className="search-mob">
            <input type="text" placeholder="Search" />
            <button variant="outline-success" className="btn-search-mob">
              <div>
                <Lupita width={20} style={{fill: "white"}} />
              </div>
            </button>
          </form> */}

          <ul className="ul-header-mob">
            <Link to="/heladeras">
              <li className="li-header-mob">Heladeras</li>
            </Link>
            <Link to="/microondas">
              <li className="li-header-mob">Microondas</li>
            </Link>
            <Link to="/hornos">
              <li className="li-header-mob">Hornos eléctricos</li>
            </Link>
            <Link to="/lavadoras">
              <li className="li-header-mob">Lavadoras/Secadoras</li>
            </Link>
            <Link to="/freezers">
              <li className="li-header-mob">Freezers</li>
            </Link>
          </ul>
        </div>
      )}
    </>
  );
}

export default Header;
