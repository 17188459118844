import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import Logo from "../Assets/Daewoo_logo.png";
import { ReactComponent as Facebook } from "../Assets/social-media/facebook.svg";
import { ReactComponent as Linkedin } from "../Assets/social-media/linkedin.svg";
import { ReactComponent as Instagram } from "../Assets/social-media/instagram.svg";
import { ReactComponent as TikTok } from "../Assets/social-media/tiktok.svg";
import { ReactComponent as Twitter } from "../Assets/social-media/twitter.svg";
import { ReactComponent as Lupita } from "../Assets/lupita.svg";
import { ReactComponent as ArrowDown } from "../Assets/arrow-down.svg";
import Search from "./Product/Search";

function Footer() {
  return (
    <>
      <hr className="footer-hr"></hr>
      <div className="footer-cont">
        <div>
          <Link to="/">
            <img src={Logo} width={200} alt="logo" className="logo-footer" />
          </Link>
          <Search />
        </div>
        <div>
          <ul className="ul-footer">
            <li className="title-footer">Categorías</li>

            <Link to="/heladeras">
              <li className="li-footer">Heladeras</li>
            </Link>
            <Link to="/microondas">
              <li className="li-footer">Microondas</li>
            </Link>
            <Link to="/hornos">
              <li className="li-footer">Hornos eléctricos</li>
            </Link>
            <Link to="/lavadoras">
              <li className="li-footer">Lavadoras/Secadoras</li>
            </Link>

            <Link to="/freezers">
              <li className="li-footer">Freezers</li>
            </Link>
          </ul>
        </div>
        <div>
          <ul className="ul-footer explorar">
            <li className="title-footer">Explorar</li>

            <Link
              to="https://www.paris.cl/search?q=Daewoo&prefn1=isMarketplace&prefv1=Paris"
              target="_blank"
            >
              <li className="li-footer">Tienda Paris</li>
            </Link>

            <Link to="https://www.daewoolatam.com/contactanos/" target="_blank">
              <li className="li-footer">Contacto</li>
            </Link>
          </ul>
        </div>
        <div>
          <ul className="ul-footer redes">
            {/* <li
              className="title-footer title-redes"
              style={{marginBottom: "14px"}}
            >
              Redes
            </li>
            <li className="li-redes">
              <Facebook
                width={20}
                style={{marginBottom: "14px", fill: "black"}}
              />
            </li>
            <li className="li-redes">
              <Linkedin
                width={20}
                style={{marginBottom: "14px", fill: "black"}}
              />
            </li>
            <li className="li-redes">
              <Instagram
                width={20}
                style={{marginBottom: "14px", fill: "black"}}
              />
            </li>
            <li className="li-redes">
              <TikTok
                width={20}
                style={{marginBottom: "14px", fill: "black"}}
              />
            </li>
            <li className="li-redes">
              <Twitter width={20} />
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Footer;
