import { Routes, Route } from "react-router-dom";
import Header from "./Pages/Header";
import Footer from "./Pages/Footer";
import Home from "./Pages/Home/Home";
import Search from "./Pages/Product/Search";
import Microondas from "./Pages/Product/Microondas";
import Heladeras from "./Pages/Product/Heladeras";
import Lavadoras from "./Pages/Product/Lavadoras";
import Freezers from "./Pages/Product/Freezers";
import HornosElectricos from "./Pages/Product/HornosElectricos";
import ScrollToTop from "./Hooks/ScrollToTop";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/microondas" element={<Microondas />} />
        <Route path="/heladeras" element={<Heladeras />} />
        <Route path="/lavadoras" element={<Lavadoras />} />
        <Route path="/freezers" element={<Freezers />} />
        <Route path="/hornos" element={<HornosElectricos />} />

        <Route path="/search" element={<Search />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
