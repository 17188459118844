import React from "react";
import Banners from "./Banners";
import Hero from "./Hero";
import "./Hero.css";

function Home() {
  return (
    <>
      <Hero />
      <Banners />
    </>
  );
}

export default Home;
