import React from "react";
import "./Hero.css";
import HeroGradient from "../../Assets/hero-gradient-comeon-01.png";
import Heladera from "../../Assets/products/heladera.png";
import Microondas from "../../Assets/products/microondas.png";
import Horno from "../../Assets/products/hornito.png";
import Lavadora from "../../Assets/products/lavadora.png";
import Freezer from "../../Assets/products/freezer.png";
import Hero1 from "../../Assets/hero01.jpg";

import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
function Hero() {
  return (
    <>
      <div className="home-hero-cont">
        <Carousel
          fade
          controls={false}
          indicators={false}
          pause={false}
          interval={3000}
        >
          <Carousel.Item>
            <div className="hero1"></div>
            <Carousel.Caption>
              <div className="hero-sub-cont">
                <div className="hero-left">
                  <div className="banner">
                    <h3 className="banner-title">
                      Tecnología <br></br>diseñada
                    </h3>
                    <p className="banner-subtitle">
                      <span style={{ fontWeight: "200" }}> para hacer</span>
                      <br></br>tu vida más simple
                    </p>
                  </div>
                </div>
                <div className="hero-right">
                  <img src={HeroGradient} id="img-hero" alt="hero-gradient" />
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div className="hero2"></div>

            <Carousel.Caption>
              <div className="hero-sub-cont">
                <div className="hero-left">
                  <div className="banner">
                    <h3 className="banner-title">
                      DAEWOO ENTRA<br></br>EN TU HOGAR
                    </h3>
                    <p className="banner-subtitle">
                      <span style={{ fontWeight: "200" }}> para CONSTRUIR</span>
                      <br></br>SU FUTURO
                    </p>
                  </div>
                </div>
                <div className="hero-right">
                  <img src={HeroGradient} id="img-hero" alt="hero-gradient" />
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div className="hero3"></div>
            <Carousel.Caption>
              <div className="hero-sub-cont">
                <div className="hero-left">
                  <div className="banner">
                    <h3 className="banner-title">
                      la vida en casa, <br></br>más simple
                    </h3>
                    <p className="banner-subtitle">
                      <span style={{ fontWeight: "200" }}> Tecnología</span>{" "}
                      <br></br>hecha para tí
                    </p>
                  </div>
                </div>
                <div className="hero-right">
                  <img src={HeroGradient} id="img-hero" alt="hero-gradient" />
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="home-cont-categories">
        <h3 className="title-categories">EXPLORA NUESTRAS CATEGORÍAS</h3>
        <div className="box-cont-categories">
          <Link to={"/heladeras"}>
            <div className="box-category">
              <img src={Heladera} className="crock" alt="category-img" />
              <p style={{ color: "black" }}>Heladeras</p>
            </div>
          </Link>
          <Link to={"/microondas"}>
            <div className="box-category">
              <img src={Microondas} className="crock" alt="category-img" />
              <p style={{ color: "black" }}>Microondas</p>
            </div>
          </Link>
          <Link to={"/hornos"}>
            <div className="box-category">
              <img src={Horno} className="crock" alt="category-img" />
              <p style={{ color: "black" }}>Hornos eléctricos</p>
            </div>
          </Link>
          <Link to={"/lavadoras"}>
            <div className="box-category">
              <img src={Lavadora} className="crock" alt="category-img" />
              <p style={{ color: "black" }}>Lavadoras / Secadoras</p>
            </div>
          </Link>
          <Link to={"/freezers"} className="out ">
            <div className="box-category out">
              <img src={Freezer} className="crock" alt="category-img" />
              <p style={{ color: "black" }}>Freezer</p>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Hero;
